import LayoutA1 from "../../components/layout-a1"
import React from "react"
import "./enroll-info-list.css"
import { Pagination } from 'antd'
import MyNavLink from '../../components/my-nav-link'


export default class EnrollInfoList extends React.Component {
state = {
    redirect:false
  }
  componentDidMount() {
    let width=document.body.clientWidth
    console.log(width)
    this.setState({width})
  }


  render() {
    const color='#fc9816'
    const enrollsinfo=[{},{}]
    const enrolllistPhone=enrollsinfo.map((enroll,index)=>
      <div className='table-content-phone' key={index}>
        <div className="content-item-phone">
          <div className="item-title">报名项目</div>
          <div>太湖马拉松-全马</div>
        </div>
        <div className="content-item-phone">
          <div className="item-title">报名人</div>
          <div>张迈</div>
        </div>
        <div className="content-item-phone">
          <div className="item-title">报名状态</div>
          <div>等待审核</div>
        </div>
        <div className="content-item-phone">
          <div className="item-title">报名时间</div>
          <div>2019-11-05 10:50</div>
        </div>
        <div  onClick={this.routePush}>
          <div className='view-details'style={{color:'black',background:color}}>
            <div className='view-details-text' >查看详情</div>
          </div>
        </div>
      </div>)
    const enrolllist=enrollsinfo.map((enroll,index)=>
      <div className='table-content' key={index}>
        <div style={{flex:2}}>
          太湖马拉松-全马
        </div>
        <div style={{flex:1}}>张迈</div>
        <div style={{flex:1}}>等待审核</div>
        <div style={{flex:1.5}}>2019-11-05 10:50</div>
        <MyNavLink style={{flex:1}} to="/demos/themeA1/profile/enrollInfo">
          <div className='view-details'style={{color:'black',background:color}}>
            <div className='view-details-text' >查看详情</div>
          </div>
        </MyNavLink>
      </div>)
    return(
      <LayoutA1 location={this.props.location} >
        <div className="profile-body">
        <div className="profile-container">
        <div className="list-container">
          <div className="list-title">我的报名</div>
          <div className="table-item">
            <div style={{flex:2}}>报名项目</div>
            <div style={{flex:1}}>报名人</div>
            <div style={{flex:1}}>报名状态</div>
            <div style={{flex:1.5}}>报名时间</div>
            <div style={{flex:1}}></div>
          </div>
          <div style={{width:'100%'}}>{this.state.width>=580?enrolllist:enrolllistPhone}</div>
          {/*<div className="pagination">*/}
          {/*  <Pagination defaultCurrent={1} total={10} pageSize={5}/>*/}
          {/*</div>*/}
        </div>
        </div>
        </div>
      </LayoutA1>
    )
  }
}